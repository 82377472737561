import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";

import Loader from "../components/common/Loader";

const Dashboard = lazy(() => import("../components/Dashboard"));


const Employees = lazy(() => import("../components/Employees"));
const EmployeeDetails = lazy(() => import("../components/Employees/Details"));

const Policy = lazy(() => import("../components/Policy"));
const Wellness = lazy(() => import("../components/Wellness"));
const Report = lazy(() => import("../components/Report"));
// const RestPassword = lazy(() => import("../components/ResetPassword"));
const Support = lazy(() => import("../components/Support"));
// const Profile = lazy(() => import("../components/Profile"));


const Login = lazy(() => import('../components/Login'));
const Welcome = lazy(() => import('../components/Welcome'));
const RequestToken = lazy(() => import('../components/ResetPassword/RequestToken'));
const ResetPassword = lazy(() => import('../components/ResetPassword'));
const NotFound = lazy(() => import('../components/404'));

const Users = lazy(() => import('../components/Users'));

const RouteSetup = () => {
  return (
    <Suspense
      fallback={<Loader />}
    >
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees"
          element={
            <PrivateRoute>
              <Employees />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees/:id"
          element={
            <PrivateRoute>
              <EmployeeDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/policy"
          element={
            <PrivateRoute>
              <Policy />
            </PrivateRoute>
          }
        />
        <Route
          path="/wellness"
          element={
            <PrivateRoute>
              <Wellness />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <Report />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/support"
          element={
            <PrivateRoute>
              <Support />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/reset-password" element={<RequestToken />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RouteSetup;
