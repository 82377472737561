import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { baseUrl } from '../utils';

const initialState = {
  token: typeof window !== 'undefined' && localStorage.getItem('hrToken'),
  isAuthenticated: false,
  isLoading: false,
  error: null,
  resetRequestSuccess: false,
  users: {
    property: {
      empty: false,
      first: false,
      last: false,
      page_number: 0,
      page_size: 0,
      sort: {
        empty: false,
        sorted: false,
        unsorted: false
      },
      total_elements: 0,
      total_pages: 0
    },
    data: []
  }
};

export const LoginWithEmail = createAsyncThunk('user/Login', async (args) => {
  try {
    return await fetch(`${baseUrl}/user/login`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: args.email,
        password: args.password,
        ref_access: 'Employer'
      })
    })
      .then(async res => {
        const resJson = await res.json()
        if (resJson.api.responseCode === 2250) {
          localStorage.setItem('hr-auth-token', res.headers.get('token'));
          localStorage.setItem('dashboard', 'Claims');
          if (args.navigate) args.navigate('/');
        }
        return resJson;
      })
  } catch (error) {
    console.log(error);
    return error.response
  }
});

export const RequestPasswordReset = createAsyncThunk('user/RequestReset', async (args) => {
  try {
    return await fetch(`${baseUrl}user/account/reset-password/init-token/${args.email}/PAS`, {
      method: 'POST',
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('hr-auth-token')
      }
    })
  } catch (error) {
    return error.response;
  }
});

export const getUsers = createAsyncThunk('user/getUsers', async ({ page, pageSize, refID }) => {
  return await fetch(`${baseUrl}/employer/profile/users`, {
    headers: {
      "content-type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('hr-auth-token')
    }
  })
    .then(res => res.json())
    .then(res => res.result)
    .catch(e => console.log(e))
});


export const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logOut: (state, actions) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.token = null;
      localStorage.clear();
    },
    clearErr: (state, actions) => {
      state.error = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(LoginWithEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(LoginWithEmail.fulfilled, (state, action) => {
      console.log(action)
      switch (action.payload?.api?.responseCode) {
        case 2250:
          state.isLoading = false;
          state.isAuthenticated = true;
          state.error = null;
          localStorage.setItem('user', JSON.stringify(action.payload?.result));
          break;
        case 5190:
          state.isLoading = false;
          state.isAuthenticated = false;
          state.error = 'Oh! There seems to be an unexpected server issue, We are trying to fix it.';
          break;
        case 3050:
          state.isLoading = false;
          state.isAuthenticated = false;
          state.error = 'Oops! We couldn\'t find the email you entered.';
          break;
        case 4150:
          state.isLoading = false;
          state.isAuthenticated = false;
          state.error = 'The password you entered is incorrect. Please try again.';
          break;
        default:
          state.isLoading = false;
          state.isAuthenticated = false;
          state.error = 'Oh! An Unexpected error occurred, Try again or report if it persists.';
          break;
      }
    });
    builder.addCase(LoginWithEmail.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(RequestPasswordReset.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(RequestPasswordReset.fulfilled, (state, action) => {
      switch (action.payload?.data?.api?.responseCode) {
        case 2130:
          state.resetRequestSuccess = true;
          state.isLoading = false;
          state.error = null;
          break;
        case 3050:
          state.isLoading = false;
          state.error = 'User Not Found';
          break;
        default:
          state.isLoading = false;
          state.error = action.payload?.data?.message;
          break;
      }
    });
    builder.addCase(RequestPasswordReset.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users.data = action.payload;
      // state.users.property = action.payload.property;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const { clearErr } = AuthSlice.actions
export default AuthSlice.reducer;
