import { Navigate } from "react-router-dom";
import decode from "jwt-decode";
import Main from "../components/common/layout/Main";

export const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("hr-auth-token");
  let isExpired = false;

  if (token) {
    const decodedToken = decode(token, { complete: true });
    if (Date.now() >= decodedToken.exp * 1000) isExpired = true;
  } else {
    isExpired = true;
  }
  return localStorage.getItem('hr-auth-token') ? <Main>{children}</Main> : <Navigate to="/login" replace />;
};
