import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAbRnazbniX9o2EFXIHJch87Q517L39Q5k',
  authDomain: 'eden-member-app.firebaseapp.com',
  projectId: 'eden-member-app',
  storageBucket: 'eden-member-app.appspot.com',
  messagingSenderId: '279948346289',
  appId: '1:279948346289:web:75f9a80c2610bc83e47390',
  measurementId: 'G-1H2ZLMPMGV',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      'BHNlEpLODaG3lFaTycHqo17qFPJvB1QB9mEhMKt13DB6uEO_H7NwNjUbS_HoV-3MHLZBrB6J_n3oUyW27_pbsn0',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
