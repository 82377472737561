/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import styles from './index.module.css';

import logoCollapsed from '../../../img/sidebarIcons/logoCollapsed.png';
import logoExpanded from '../../../img/sidebarIcons/logoExpanded.png';
import dashboard from '../../../img/sidebarIcons/dashboard.png';
import support from '../../../img/sidebarIcons/support.png';
import employees from '../../../img/sidebarIcons/employees.png';
import policy from '../../../img/sidebarIcons/policy.png';
import demoUser from '../../../img/sidebarIcons/avatar.png';

import { useLocation, useNavigate } from 'react-router-dom';
import { LogoutOutlined, UsergroupAddOutlined, MenuOutlined, CloseOutlined, WalletOutlined } from '@ant-design/icons';

const SideBar = () => {
  const [open, setOpen] = useState(false);

  const links = [
    {
      key: '',
      title: 'Dashboard',
      link: '/',
      icon: dashboard,
      hidden: true,
      width: '25px',
      marginLeft: '37px',
    },
    {
      key: 'employees',
      title: 'Employees',
      link: '/employees',
      icon: employees,
      width: '30px',
      marginLeft: '36px',
      invert: false,
    },
    {
      key: 'policy',
      title: 'Policy',
      link: '/policy',
      icon: policy,
      width: '30px',
      marginLeft: '37px',
      invert: false,
    },
    {
      key: 'support',
      title: 'Support',
      link: '/support',
      icon: support,
      width: '30px',
      marginLeft: '35px',
      invert: true,
    },
  ]

  const loc = useLocation()

  useEffect(() => setCurrent(loc.pathname.split('/')[1]), [loc])

  const [current, setCurrent] = useState(loc.pathname)
  const navigate = useNavigate()

  const [userHover, setUserHover] = useState(false)

  const logOut = () => {
    localStorage.clear()
    navigate('/login')
  }

  return (
    <div
      style={{ zIndex: '100' }}
      className={open ? styles.sideBarContainerOpen : styles.sideBarContainer}
    >
      <img src={open ? logoExpanded : logoCollapsed} style={{ width: open ? '100px' : '40px', opacity: '75%' }} />
      <div style={{ position: 'relative', width: '100%', height: '80vh', justifyContent: 'flex-start', alignItems: 'center', marginTop: open ? '10px' : '50px', display: 'flex', flexDirection: 'column', gap: '0px', padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            gap: '15px',
            alignItems: 'center',
            justifyContent: open ? 'flex-end' : 'center',
            width: '100%',
            height: '50px',
            transition: 'all 0.15s ease-in-out',
            borderRadius: '0px 15px 15px 0px',
            cursor: 'pointer',
            marginRight: '2px'
          }}
          onClick={() => setOpen(!open)}
        >
          {open ? <CloseOutlined style={{ fontSize: '22px', marginRight: '15px' }} /> : <MenuOutlined style={{ fontSize: '22px' }} />}

        </div>
        {links.map((link, index) => <div
          key={index}
          className={styles.linkContainer}
          onClick={() => navigate(link.link)}
          style={{
            backgroundColor: current === link.key ? 'rgba(58, 180, 67, 0.65)' : null,
            boxShadow: current === index ? '2px 2px 5px 2px rgba(82, 82, 82, 0.15)' : null,
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            alignItems: 'center',

          }}
        >
          <img src={link.icon} style={{
            // marginLeft: '35px',
            width: link.width,
            marginLeft: link.marginLeft,
            // filter: link.invert
            filter: (link.invert) ? (current === link.key ? 'invert(1)' : 'invert(0.45)') : (current === link.key ? 'invert(0)' : 'invert(0.45)'),
          }}
          />{open ? <span style={{ color: current === link.key ? 'white' : '' }}>{link.title}</span> : null}
        </div>

        )}
        <div
          onMouseEnter={() => setUserHover(true)}
          onMouseLeave={() => setUserHover(false)}
          className={styles.userContainer}>
          {userHover ? <div style={{ marginBottom: open ? '25px' : '', marginLeft: open ? '-10px' : '15px' }} onClick={() => navigate('/users')} className={open ? styles.userMgmtBtnOpen : styles.userMgmtBtn}><UsergroupAddOutlined style={{ marginLeft: '2px' }} /><span className={styles.btnHoverText}>Manage Users</span></div> : null}
          {userHover ?
            <div className={open ? styles.logoutBtnOpen : styles.logoutBtn} style={{ marginBottom: open ? '25px' : '', marginLeft: open ? '-10px' : '15px' }} onClick={logOut}>
              <LogoutOutlined style={{ marginLeft: '2px' }} />
              <span className={styles.btnHoverText}>Logout</span>
            </div> : null}
          <img src={demoUser} className={open ? styles.userIconOpen : styles.userIcon} />
          {open ? <span style={{ position: 'absolute', right: '45px', bottom: '15px', fontSize: '18px', fontWeight: "500" }}>{JSON.parse(localStorage.getItem('user'))?.name}</span> : null}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
